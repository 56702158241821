import React from "react";
import "./heading.css";

type HeadingProps = {
  title: string;
};

const Heading = ({ title }: HeadingProps) => {
  return (
    <>
      <div className="heading">
        <h6>{title}</h6>
      </div>
    </>
  );
};

export default Heading;
