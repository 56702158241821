import { Link } from "react-router-dom";
import Head from "./Head";
import "./header.css";
import { useState } from "react";

export const States = [
  { name: "Últimas", code: "Últimas", region: "Todas" },
  { name: "Acre", code: "AC", region: "Norte" },
  { name: "Alagoas", code: "AL", region: "Nordeste" },
  { name: "Amapá", code: "AP", region: "Norte" },
  { name: "Amazonas", code: "AM", region: "Norte" },
  { name: "Bahia", code: "BA", region: "Nordeste" },
  { name: "Ceará", code: "CE", region: "Nordeste" },
  { name: "Distrito Federal", code: "DF", region: "Centro-Oeste" },
  { name: "Espírito Santo", code: "ES", region: "Sudeste" },
  { name: "Goiás", code: "GO", region: "Centro-Oeste" },
  { name: "Maranhão", code: "MA", region: "Nordeste" },
  { name: "Mato Grosso", code: "MT", region: "Centro-Oeste" },
  { name: "Mato Grosso do Sul", code: "MS", region: "Centro-Oeste" },
  { name: "Minas Gerais", code: "MG", region: "Sudeste" },
  { name: "Pará", code: "PA", region: "Norte" },
  { name: "Paraíba", code: "PB", region: "Nordeste" },
  { name: "Paraná", code: "PR", region: "Sul" },
  { name: "Pernambuco", code: "PE", region: "Nordeste" },
  { name: "Piauí", code: "PI", region: "Nordeste" },
  { name: "Rio de Janeiro", code: "RJ", region: "Sudeste" },
  { name: "Rio Grande do Norte", code: "RN", region: "Nordeste" },
  { name: "Rio Grande do Sul", code: "RS", region: "Sul" },
  { name: "Rondônia", code: "RO", region: "Norte" },
  { name: "Roraima", code: "RR", region: "Norte" },
  { name: "Santa Catarina", code: "SC", region: "Sul" },
  { name: "São Paulo", code: "SP", region: "Sudeste" },
  { name: "Sergipe", code: "SE", region: "Nordeste" },
  { name: "Tocantins", code: "TO", region: "Norte" },
  { name: "Nacionais", code: "NACIONAIS", region: "Nacional" },
];

const Header = () => {
  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <Head />
      <header>
        <div className="container paddingSmall">
          <nav>
            <ul className={navbar ? "navbar" : "flex"} onClick={() => setNavbar(false)}>
              {States.map((state, index) =>
                index === 0 ? (
                  <li key={index}>
                    <Link to="/">HOME</Link>
                  </li>
                ) : (
                  <li key={index}>
                    <Link to={`/by-state/${state.code}`}>{state.code}</Link>
                  </li>
                )
              )}
            </ul>
            <button className="barIcon" onClick={() => setNavbar(!navbar)}>
              {navbar ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
            </button>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
