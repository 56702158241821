import "./popular.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from "../../../common/heading/Heading";
import { INews, INewsFile } from "../../../../shared/interfaces";
import { format } from "date-fns";
import { Link } from "react-router-dom";

import LogoNews from "../../../../assets/news.jpg";

type PopularProps = {
  news: INews[];
};

const Popular = ({ news }: PopularProps) => {
  const getImage = (files: INewsFile[]) => {
    const img = files.find(
      (f) => f.extension === "png" || f.extension === "jpg" || f.extension === "jpeg"
    );

    if (img && img.path) {
      return img.path;
    } else {
      return LogoNews;
    }
  };

  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 2,
    speed: 500,
    rows: 4,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 4,
        },
      },
    ],
  };
  return (
    <>
      <section className="popular">
        <Heading title="Últimas" />
        <div className="content">
          <Slider {...settings}>
            {news.map((val) => {
              return (
                <div key={val._id} className="items">
                  <div className="box shadow">
                    <div className="images row">
                      <div className="img">
                        <img src={getImage(val.files)} alt="" />
                      </div>
                      <div className="category category1">
                        <span>{val.state}</span>
                      </div>
                    </div>
                    <div className="text row">
                      <Link to={`/SinglePage/${val._id}`}>
                        <h1 className="title">{val.title.slice(0, 40)}...</h1>
                      </Link>
                      <div className="date">
                        <i className="fas fa-calendar-days"></i>
                        <label>{format(val.updated_at, "dd/MM/yyyy HH:mm")}</label>
                      </div>
                      <div className="comment">
                        <label>{val.subtitle}</label>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Popular;
