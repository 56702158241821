import Side from "../../sideContent/side/Side";
import "./style.css";
import Popular from "../popular/Popular";
import { INews } from "../../../../shared/interfaces";

type HomesProps = {
  news: INews[];
};

const Homes = ({ news }: HomesProps) => {
  return (
    <>
      <main>
        {news.length > 0 && (
          <div className="container">
            <section className="mainContent">
              <Popular news={news} />
            </section>
            <section className="sideContent">
              <Side />
            </section>
          </div>
        )}
      </main>
    </>
  );
};

export default Homes;
