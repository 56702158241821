import { toast } from "react-toastify";
import { api } from "./api";
import { ISubscribe } from "../shared/interfaces";

export async function create({ email }: ISubscribe) {
  return await api
    .post(
      "/subscribe",
      {
        email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      toast.success("Cadastro realizado com sucesso");
      return true;
    })
    .catch((error) => {
      toast.error("Não foi possível realizar o cadastro.\nTente novamente");
      console.error(error);
      return false;
    });
}
