import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../home/mainContent/homes/style.css";
import "./singlePage.css";
import "../home/sideContent/side/side.css";
import Side from "../home/sideContent/side/Side";
import { INews, INewsFile } from "../../shared/interfaces";
import { findAll, findById } from "../../services/news";
import { format } from "date-fns";
import { createMarkup } from "../../shared/utils";
import Popular from "../home/mainContent/popular/Popular";
import Loader from "../loader/Loader";
import LogoNews from "../../assets/news.jpg";

const SinglePage = () => {
  const { id } = useParams<{ id: string }>();
  const [item, setItem] = useState<INews | null>(null);
  const [myNews, setMyNews] = useState<INews[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function getTheNews(id: string) {
      setIsLoading(true);

      const item = await findById(id);
      window.scrollTo(0, 0);
      if (item) {
        setItem(item);
      }

      const news = await findAll(null, "1");
      setMyNews(news);
      setIsLoading(false);
    }
    if (id) getTheNews(id);
  }, [id]);

  const getImage = (files: INewsFile[]) => {
    const img = files.find(
      (f) => f.extension === "png" || f.extension === "jpg" || f.extension === "jpeg"
    );

    if (img && img.path) {
      return img.path;
    } else {
      return LogoNews;
    }
  };

  return (
    <main>
      {!isLoading && item ? (
        <>
          <div className="container">
            <section className="mainContent details">
              <h1 className="title">{item.title}</h1>{" "}
              <div className="author">
                <p>{item.subtitle}</p>
                <label>{format(item.updated_at, "dd/MM/yyyy HH:mm")}</label>
              </div>
              <img src={getImage(item.files)} alt="news" />
              <div className="desctop" dangerouslySetInnerHTML={createMarkup(item.content)}></div>
            </section>
            <section className="sideContent">
              <Side />
            </section>
          </div>
          <div className="container">
            <Popular news={myNews} />
          </div>
        </>
      ) : (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Loader isLoading={isLoading} size={100} color="#44a3a5" />
        </div>
      )}
    </main>
  );
};

export default SinglePage;
