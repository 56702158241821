import { useEffect, useState } from "react";
import Hero from "./hero/Hero";
import Homes from "./mainContent/homes/Home";
import { findAll } from "../../services/news";
import { INews } from "../../shared/interfaces";
import AdSenseAd from "../common/ad-sense-ad/AdSenseAd";
import Loader from "../loader/Loader";

const HomePage = () => {
  const [myNews, setMyNews] = useState<INews[]>([]);
  const [heroNews, setHeroNews] = useState<INews[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function getNews() {
      setIsLoading(true);
      const news = await findAll(null, "1");
      setHeroNews([]);
      setMyNews([]);

      for (let index = 0; index < 4; index++) {
        setHeroNews((prevState) => [...prevState, news[index]]);
      }

      if (news.length < 20) {
        setMyNews(news);
      } else {
        for (let index = 4; index < 20; index++) {
          setMyNews((prevState) => [...prevState, news[index]]);
        }
      }
      setIsLoading(false);
    }

    getNews();
  }, []);

  return (
    <>
      {isLoading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <AdSenseAd />
          <Loader isLoading={isLoading} size={100} color="#44a3a5" />
          <AdSenseAd />
        </div>
      ) : (
        <>
          <Hero news={heroNews} />
          <AdSenseAd />
          <Homes news={myNews} />
          <AdSenseAd />
        </>
      )}
    </>
  );
};

export default HomePage;
