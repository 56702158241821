import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import Header from "./components/common/header/Header";
import HomePage from "./components/home/HomePage";
import Footer from "./components/common/footer/Footer";
import SinglePage from "./components/singlePage/SinglePage";
import ByState from "./components/byState/ByState";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/singlepage/:id" exact component={SinglePage} />
          <Route path="/by-state/:state" component={ByState} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
