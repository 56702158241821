import { useEffect, useState } from "react";
import AdSenseAd from "../common/ad-sense-ad/AdSenseAd";
import Homes from "../home/mainContent/homes/Home";
import { findAll } from "../../services/news";
import { INews } from "../../shared/interfaces";
import { useParams } from "react-router-dom";

const ByState = () => {
  const { state } = useParams<{ state: string }>();
  const [myNews, setMyNews] = useState<INews[]>([]);

  useEffect(() => {
    async function getNews() {
      setMyNews([]);
      const news = await findAll(state, "1");
      setMyNews(news);
    }

    getNews();
  }, [state]);

  return (
    <>
      <AdSenseAd />
      <Homes news={myNews} />
      <AdSenseAd />
    </>
  );
};

export default ByState;
