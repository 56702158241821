import React from "react";
import { ClipLoader } from "react-spinners";

interface LoaderProps {
  isLoading: boolean;
  size: number;
  color?: string;
}

const Loader = ({ isLoading, size, color }: LoaderProps) => {
  return (
    <div className="px-4 py-6 sm:px-0 flex flex-row justify-center items-center gap-4">
      <ClipLoader
        color={color ?? "#28696a"}
        loading={isLoading}
        size={size}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
