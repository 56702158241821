import "./side.css";

import Heading from "../../../common/heading/Heading";
import AdSenseAd from "../../../common/ad-sense-ad/AdSenseAd";
import { useState } from "react";
import { create } from "../../../../services/subscribe";

const Side = () => {
  const [email, setEmail] = useState("");

  async function handleSave(e: any) {
    e.preventDefault();
    await create({ email });
    setEmail("");
  }

  return (
    <>
      {/* <Heading title="Últimas" />
      <SocialMedia /> */}

      <Heading title="Subscribe" />

      <section className="subscribe">
        <h1 className="title">Se inscreva para receber as notícias</h1>
        <form action="">
          <input
            type="email"
            placeholder="Seu e-mail..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button disabled={email.length === 0} onClick={handleSave}>
            <i className="fa fa-paper-plane"></i> Registrar
          </button>
        </form>
      </section>
      <AdSenseAd />
    </>
  );
};

export default Side;
