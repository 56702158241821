import { Link } from "react-router-dom";
import { INews, INewsFile } from "../../../shared/interfaces";
import { format } from "date-fns";
import LogoNews from "../../../assets/news.jpg";

type CardProps = {
  item: INews;
};

const Card = ({ item: { _id, state, title, updated_at, files } }: CardProps) => {
  const getImage = (files: INewsFile[]) => {
    const img = files.find(
      (f) => f.extension === "png" || f.extension === "jpg" || f.extension === "jpeg"
    );

    if (img && img.path) {
      return img.path;
    } else {
      return LogoNews;
    }
  };

  return (
    <>
      <div className="box">
        <div className="img">
          <img src={getImage(files)} alt="" />
        </div>
        <div className="text">
          <span className="category">{state}</span>
          <Link to={`/SinglePage/${_id}`}>
            <h1 className="titleBg">{title}</h1>
          </Link>
          <div className="date">
            <i className="fas fa-calendar-days"></i>
            <label>{format(updated_at, "dd/MM/yyyy HH:mm")}</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
