import { Adsense } from "@ctrl/react-adsense";

const AdSenseAd = () => {
  return (
    <Adsense
      className="ExampleAdSlot"
      client="ca-pub-2374978869002134"
      slot="2222222"
      // adTest="on"
    />
  );
};

export default AdSenseAd;
