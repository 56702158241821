import { toast } from "react-toastify";
import { api } from "./api";
import { INews } from "../shared/interfaces";

export const States = [
  { name: "Todos", code: "Todos", region: "Todas" },
  { name: "Acre", code: "AC", region: "Norte" },
  { name: "Alagoas", code: "AL", region: "Nordeste" },
  { name: "Amapá", code: "AP", region: "Norte" },
  { name: "Amazonas", code: "AM", region: "Norte" },
  { name: "Bahia", code: "BA", region: "Nordeste" },
  { name: "Ceará", code: "CE", region: "Nordeste" },
  { name: "Distrito Federal", code: "DF", region: "Centro-Oeste" },
  { name: "Espírito Santo", code: "ES", region: "Sudeste" },
  { name: "Goiás", code: "GO", region: "Centro-Oeste" },
  { name: "Maranhão", code: "MA", region: "Nordeste" },
  { name: "Mato Grosso", code: "MT", region: "Centro-Oeste" },
  { name: "Mato Grosso do Sul", code: "MS", region: "Centro-Oeste" },
  { name: "Minas Gerais", code: "MG", region: "Sudeste" },
  { name: "Pará", code: "PA", region: "Norte" },
  { name: "Paraíba", code: "PB", region: "Nordeste" },
  { name: "Paraná", code: "PR", region: "Sul" },
  { name: "Pernambuco", code: "PE", region: "Nordeste" },
  { name: "Piauí", code: "PI", region: "Nordeste" },
  { name: "Rio de Janeiro", code: "RJ", region: "Sudeste" },
  { name: "Rio Grande do Norte", code: "RN", region: "Nordeste" },
  { name: "Rio Grande do Sul", code: "RS", region: "Sul" },
  { name: "Rondônia", code: "RO", region: "Norte" },
  { name: "Roraima", code: "RR", region: "Norte" },
  { name: "Santa Catarina", code: "SC", region: "Sul" },
  { name: "São Paulo", code: "SP", region: "Sudeste" },
  { name: "Sergipe", code: "SE", region: "Nordeste" },
  { name: "Tocantins", code: "TO", region: "Norte" },
];

export async function findAll(state: string | null, lastNews: string | null) {
  let params = "";
  if (state) {
    params = state && state !== "Todos" ? `state=${state}` : "";
  }
  if (lastNews) {
    params += params.length > 0 ? `&lastNews=${lastNews}` : `lastNews=${lastNews}`;
  }

  return (await api
    .get(`/news/app?${params}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      toast.error("Não foi possível buscar os dados.\nTente novamente");
      console.error(error);
      return [];
    })) as INews[];
}

export async function findById(id: string) {
  return (await api
    .get(`/news/app/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error("Não foi possível buscar os dados.\nTente novamente");
      console.error(error);
      return null;
    })) as INews;
}
