import "./hero.css";
import Card from "./Card";
import { INews } from "../../../shared/interfaces";

export type HeroData = {
  id: number;
  cover: string;
  category: string;
  title: string;
  authorName: string;
  authorImg: string;
  time: string;
  desc: any;
  details: any;
};

type HeroProps = {
  news: INews[];
};

const Hero = ({ news }: HeroProps) => {
  return (
    <>
      {news.length > 0 && (
        <section className="hero">
          <div className="container">
            {news.map((item) => {
              return <Card key={item._id} item={item} />;
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default Hero;
